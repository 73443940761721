<template>
    <modal ref="modalCambiarPosicionImagen" titulo="Ordenar imagenes" tamano="modal-sm" no-aceptar adicional="Guardar" :cargado-modal="loading" @adicional="guardar">
        <draggable :list="imagenes" handle=".handle">
            <div v-for="imagen in imagenes" :key="imagen.id" class="row mx-0 justify-center align-items-center mb-2 pr-5">
                <div class="d-middle" style="width:110px;">
                    <i class="icon-drag text-gris handle f-26" />
                    <img
                    :src="imagen.miniatura"
                    width="78"
                    height="78"
                    class="obj-cover br-4 border"
                    />
                </div>
            </div>
        </draggable>
    </modal>
</template>

<script>
import Publicidad from "~/services/publicidad/publicidad_admin";
export default {
    data(){
        return {
            imagenes: [],
            loading: false
        }
    },
    methods: {
        toggle(imagenes){
            this.imagenes = [...imagenes]
            this.$refs.modalCambiarPosicionImagen.toggle()
        },
        async guardar(){
            try {
                this.loading = true
                let params = {
                    imagenes: this.imagenes.map(el => el.id)
                }

                const { data } = await Publicidad.cambiarPosicionImagenes(params)
                if (data.exito){
                    this.$emit('update')
                    this.$refs.modalCambiarPosicionImagen.toggle()

                }

            } catch (error){
                this.error_catch(error)
            } finally{
                this.loading = false
            }
        },
    }
}
</script>

<style>

</style>